import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import axios from "axios"
import { defaultVoteItems } from "../components/defaultVoteItems"
import { db } from "../firebase"

const Nachricht = styled(
  ({ name, names, available, commited, declined, dinner, className }) => (
    <div className={className}>
      <h3>
        <Link to={`/${name}`}>{names.join(", ")}</Link>
      </h3>
      <p>
        <strong>Verfügbare Plätze:</strong> {available}
      </p>
      <p>
        <strong>Zusagen:</strong> {commited}
      </p>
      <p>
        <strong>Absagen:</strong> {declined}
      </p>
      <p>
        <strong>Vorfeiern</strong> {dinner ? "ja" : "nein"}
      </p>
    </div>
  )
)`
  margin: 20px 10px;
  padding: 0 0 10px;
  border-bottom: 1px solid #ddd;
`

export default function AllNachricht() {
  const [data, setData] = useState([])

  useEffect(() => {
    return db.collection("guests").onSnapshot(snap => {
      const items = []
      snap.forEach(doc => {
        items.push(doc.data())
      })
      setData(items)
    })
  }, [])

  const aggr = data.reduce(
    (memo, value) => {
      const amount = Math.max(value.names.length, value.available) || 0
      const committed =
        value.available === 1 && value.commited === 1 ? amount : value.commited

      console.log({ name: value.name, amount, committed })
      return {
        allCount: memo.allCount + amount,
        committed: memo.committed + committed,
        dinner: memo.dinner + (value.dinner ? 1 : 0),
      }
    },
    {
      allCount: 0,
      committed: 0,
      dinner: 0,
    }
  )

  return (
    <>
      Alle {aggr.allCount} | Zusagen {aggr.committed} | Dinner {aggr.dinner}
      {data.map(item => {
        return <Nachricht key={item.name} {...item} />
      })}
    </>
  )
}
